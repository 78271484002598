







































































































































































































































































































































































































import Vue from "vue";
import Snackbar from "@/components/shared/Snackbar.vue";
import VueApexCharts from "vue-apexcharts";
import { mapGetters } from "vuex";
import { GET_USER_DETAILS } from "@/store/modules/auth";
export default Vue.extend({
  data: (): Record<string, any> => ({
    tabs: null,
    //snackbar
    snackbar_notification: false,
    notification: "",
    emiratisation_statistics_graph_series: [],
    emiratisation_statistics_graph_options: {
      chart: {
        type: "bar",
        toolbar: {
          show: false
        }
      },
      tooltip: {
        enabled: false
      },
      dataLabels: {
        enabled: false
      },
      plotOptions: {
        bar: {
          horizontal: true,
          borderRadius: 4,
          borderRadiusApplication: "end",
          barHeight: "17px",
          colors: {
            ranges: [
              {
                from: 50,
                to: 100,
                color: "#3361FF"
              },
              {
                from: 0,
                to: 49,
                name: "Locals",
                color: "#FF6633"
              }
            ]
          }
        }
      },
      legend: {
        show: false
      },
      xaxis: {
        categories: ["Foreigners", "Locals"],
        labels: {
          show: true,
          formatter: function (value: number) {
            return value + "%";
          },
          style: {
            colors: ["#334466"],
            fontSize: "12px",
            fontFamily: "Roboto",
            fontWeight: 400
          }
        }
      },
      yaxis: {
        labels: {
          show: true,
          style: {
            colors: ["#3F5073"],
            fontSize: "14px",
            fontFamily: "Roboto",
            fontWeight: 500
          }
        }
      },
      grid: {
        show: true,
        borderColor: "#EDEFF2",
        strokeDashArray: 0,
        position: "back",
        xaxis: {
          lines: {
            show: true
          }
        },
        yaxis: {
          lines: {
            show: true
          }
        }
      }
    },
    highlighted_talents: [
      {
        name: "Adams Allen",
        description:
          "The Indeed Editorial Team comprises a diverse and talented team",
        picture: require("@/assets/profile_pictures/adams_allen.png")
      },
      {
        name: "Anderson Armstrong",
        description: "The human resource (HR) department at your company is",
        picture: require("@/assets/profile_pictures/anderson_armstrong.png")
      },
      {
        name: "Florence",
        description:
          "It's best to record what this person said to you and when the",
        picture: require("@/assets/profile_pictures/florence.png")
      },
      {
        name: "Charlotte",
        description:
          "The same is true if you are experiencing something that is making",
        picture: require("@/assets/profile_pictures/charlotte.png")
      }
    ],
    key_skill_capabilities_items: [
      {
        title: "Communication",
        value: 79
      },
      {
        title: "Teamwork",
        value: 83
      },
      {
        title: "Problem-solving",
        value: 74
      },
      {
        title: "Computer / IT skills",
        value: 87
      },
      {
        title: "Leadership",
        value: 74
      },
      {
        title: "Hard work and dedication",
        value: 74
      },
      {
        title: "Creativity",
        value: 71
      }
    ],
    hiring_position_items: [
      {
        title: "Computer Scientist",
        description:
          "We are looking for a Computer Scientist to join our team and help our departments automate aspects",
        numbers: 50
      },
      {
        title: "Software Engineer",
        description:
          "We are looking for a skilled software engineer who, along with our excellent software development team",
        numbers: 32
      },
      {
        title: "IT & Technical Support Specialist",
        description:
          "We are looking for a skilled software engineer who, along with our excellent software development team",
        numbers: 21
      },
      {
        title: "IT Project Coordinator",
        description:
          "We are looking for a skilled software engineer who, along with our excellent software development team",
        numbers: 200
      },
      {
        title: "Systems Administrator",
        description:
          "We are looking for a skilled software engineer who, along with our excellent software development team",
        numbers: 59
      },
      {
        title: "Customer support specialist",
        description:
          "We are looking for a skilled software engineer who, along with our excellent software development team...",
        numbers: 163
      }
    ]
  }),
  created() {
    setTimeout(() => {
      this.emiratisation_statistics_graph_series = [
        {
          data: [78, 25]
        }
      ];
    }, 150);
  },
  components: {
    Snackbar,
    "vue-apex-charts": VueApexCharts
  },
  computed: {
    ...mapGetters("auth", {
      get_user_details: GET_USER_DETAILS
    })
  },
  methods: {
    snackbar_notification_click() {
      this.notification = "";
      this.snackbar_notification = false;
    }
  }
});
